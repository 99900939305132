/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast, ToastContainer } from 'react-toastify';
import { addConventions, addFileConvention_1, getSelectCategorie, typeConvention } from '../../../services/Magasins/PdvApi'
import { getListeSousFamilles, getSelecteMarque, getSelectFamilles, getSelectGamme } from '../../../services/Produits/configurationProduits/ConfigurationAPI';
import { getSelctEnseignes, getSelectProduits } from '../../../services/Produits/ProduitsAPI'
import convention from './modele_convention_1.xlsx'

export default function AjouterConventiion() {


    const produits = useRef();
    const categorie = useRef();
    const [libelle, setlibelle] = useState("")
    const [listeTypes, setlisteTypes] = useState([])
    const [codeType, setcodeType] = useState(-1)
    const [datedebut, setdatedebut] = useState("")
    const [datefin, setdatefin] = useState("")
    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [listeCategorie, setlisteCategorie] = useState([])
    const [codeCategorie, setcodeCategorie] = useState("")
    const [listeProduits, setListeProduits] = useState([])
    const [codeproduit, setcodeproduit] = useState("")
    const [listeGamme, setlisteGamme] = useState([])
    const [listeMarque, setlisteMarque] = useState([])
    const [listeFamille, setlisteFamille] = useState([])
    const [listeSFamille, setlisteSFamille] = useState([])
    const [codeGamme, setcodeGamme] = useState("")
    const [codeMarque, setcodeMarque] = useState("")
    const [codeFamille, setcodeFamille] = useState(-1)
    const [codeSfamille, setcodeSfamille] = useState(-1)
    /*** PL CHAMps */
    const [plContrat, setplContrat] = useState("")
    const [implentation, setimplentation] = useState("")
    // keeped state:
    const [etat, setetat] = useState();
    const [etat2, setetat2] = useState()
    const [filename, setFileName] = useState("Choisir un fichier ")
    const [file, setfile] = useState("");

       //------------- Traduction ---------------
   const [t, i18n] = useTranslation("global");


    // change handling:
    const handleChange = e => {
        const target = e.target;
        if (target.checked) {
            setetat(target.value);
        }
    };
    // change handling:
    const handleChangeEtat = e => {
        const target = e.target;
        if (target.checked) {
            setetat2(target.value);
        }
    };
    useEffect(() => {
        typeConvention().then((result) => {
            if (result?.data?.success === 1) {
                setlisteTypes(result?.data?.type)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelctEnseignes().then((result) => {
            if (result?.data?.success === 1) {
                setlisteEnseignes(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectProduits().then((result) => {
            if (result?.data?.success === 1) {
                setListeProduits(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        getSelectGamme().then((result) => {
            if (result?.data?.success === 1) {
                setlisteGamme(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectFamilles().then((result) => {
            if (result?.data?.success === 1) {
                setlisteFamille(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getListeSousFamilles().then((result) => {
            if (result?.data?.success === 1) {
                setlisteSFamille(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelecteMarque().then((result) => {
            if (result?.data?.success === 1) {
                setlisteMarque(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectCategorie()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteCategorie(result?.data?.data)
                }
            }).catch((err) => {

            });
    }, [])
    // Handle Change Famille
    const handleProduits = (...args) => {
        setcodeproduit(args[1].value)
    };
    // Handle Change Famille
    const handleCategorie = (...args) => {
        setcodeCategorie(args[1].value)
    };
    //UPLOAD IMAGE WITH PREVIEW image Commerce
    const selectFile = (event) => {
        setFileName(event?.target?.files[0]?.name)
        if (event?.target?.files?.length) {
            setfile(event?.target?.files[0])
        }
    }

    const saveData = () => {
        if (datedebut > datefin) {
            toast(t('messages.dateinf'), { type: 'error', position: 'top-right' })
        } else if (datedebut === "" || datefin === "" || libelle === "" || codeEnseigne === "" || codeCategorie === "" ) {
            toast(t('conventions.messageformulaire'), { type: 'error', position: 'top-right' })
        } else {
            const data = {
                libelle: libelle,
                date_debut: datedebut,
                date_fin: datefin,
                type: codeType,
                enseigne_code: codeEnseigne,
                categorie_code: codeCategorie,

            }
            Object.assign(data, codeType === 'pl' ? { plcontrat: plContrat, implentation: implentation } : {});
            Object.assign(data, codeproduit !== "" ? { produit_code: codeproduit } : {})
            Object.assign(data, codeGamme !== "" ? { gamme_code: codeGamme } : {})
            Object.assign(data, codeMarque !== "" ? { marque_code: codeMarque } : {})
            Object.assign(data, codeFamille !== -1 ? { famille_code: codeFamille } : {})
            Object.assign(data, codeSfamille !== -1 ? { sousfamille_code: codeSfamille } : {})

            addConventions(data).then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('messageErreur.ajoutavecsuccee'), { type: "success", position: 'top-right' })
                }
            }).catch((err) => {
                console.log(err)
            });
        }
    }
    const uploadFile = () => {
        if (datedebut > datefin) {
            toast(t('messages.dateinf'), { type: 'error', position: 'top-right' })
        } else if (datedebut === "" || datefin === "" || libelle === "" || codeEnseigne === "" || codeType === -1) {
            toast(t('conventions.messageformulaire'), { type: 'error', position: 'top-right' })

        } else {
        const data = {
            libelle: libelle,
            date_debut: datedebut,
            date_fin: datefin,
            enseigne_code : codeEnseigne,
            type: codeType}

            Object.assign(data, codeType === 'pl' ? { plcontrat: plContrat, implentation: implentation } : {});
        // FILE FUNCTIONS
        addFileConvention_1(data,file)
            .then((result) => {
                console.log(result)
                if (result?.data?.success === 1) {
                    toast(t('messageErreur.ajoutavecsuccee'), { type: "success", position: 'top-right' })
                } else {
                    toast(t('conventions.problemedajout'), { type: "error", position: 'top-right' })

                }
            }).catch((err) => {
                console.log(err)

            })
        }
    }
    return (
        <div >
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('conventions.convention')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('conventions.ajouterConvention')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-plus mr-1" />
                            {t('Magasins.AjouterPDV')}
                        </a>
                        {/* <a href="/pdv/groupePDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-organization mr-1" />
                            Groupe PDV
                        </a>
 */}
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mt-3">{t('conventions.ajouterConvention')}</h5>
                </div>
                <ToastContainer />
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">

                                    <div className="form-group">
                                        <label className="form-label">{t('dashboard.Libelle')}  <span className="text-danger">*</span></label>
                                        <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" name="example-text-input" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="col-md-4">

                                    <div className="form-group">
                                        <label className="form-label">{t('dashboard.type')}</label>
                                        <select name="country" value={codeType} onChange={(e) => setcodeType(e.target.value)} className="form-control  custom-select">
                                            <option>---- {t('dashboard.type')} -----</option>
                                            <option  value={"rupture"}>Rupture</option>
                                            <option  value={"pl"}>Part Linéaire</option>
                                            <option  value={"promotion"}>Promotion</option>
                                            <option  value={"gratuite"}>Gratuité</option>
                                            <option  value={"dn"}>DN</option>
{/* 
                                            {listeTypes?.map((items, keys) => (
                                                <option key={keys} value={items.type}>{items.type}</option>
                                            ))} */}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="form-label">{t('rapports.Enseignes')}</label>
                                        <select name="country" value={codeEnseigne} onChange={(e) => setcodeEnseigne(e.target.value)} className="form-control  custom-select">
                                            <option>---- {t('rapports.Enseignes')} -----</option>
                                            {listeEnseignes?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {codeType === "pl" ?
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label className="form-label">{t('conventions.plcontractuelle')} </label>
                                        <input type="text" value={plContrat} onChange={(e) => setplContrat(e.target.value)} className="form-control" name="example-disabled-input" placeholder="plContrat" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label className="form-label">{t('conventions.implentation')}</label>
                                        <input type="text" value={implentation} onChange={(e) => setimplentation(e.target.value)} className="form-control" name="example-disabled-input" placeholder="Implementation" />
                                    </div>
                                </div> : null}
                            <div className="row">
                                <div className="form-group col-6">
                                    <label className="form-label">{t('Routings.datedebut')}  </label>
                                    <input type="date" value={datedebut} onChange={(e) => setdatedebut(e.target.value)} className="form-control" name="example-disabled-input" placeholder="Date Début" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="form-label">{t('Routings.datefin')}  </label>
                                    <input type="date" value={datefin} onChange={(e) => setdatefin(e.target.value)} className="form-control" name="example-disabled-input" placeholder="Date Début" />
                                </div>
                            </div>
                            <div className="form-group text-center">
                                <label className="form-label">{t('conventions.choisiroption')}</label>
                                <div>
                                    <label className="mr-2">
                                        <input type="radio" value="ajouter" className=" mr-2" checked={etat === 'ajouter'}
                                            onChange={handleChange} />
                                        <span>{t('conventions.ajouterConvention')}</span>
                                    </label>
                                    <label className="mr-2">
                                        <input className="mr-2" type="radio" value="importer" checked={etat === 'importer'}
                                            onChange={handleChange} />
                                        <span>{t('conventions.importerConvention')}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {etat === 'ajouter' ? (
                            <>
                                <div className="col-md-6 ">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapportrupture.produit')}  <span className="text-danger">*</span></label>
                                        <SelectSearch
                                            options={listeProduits}
                                            search
                                            ref={produits}
                                            onChange={handleProduits}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Produits.choisirProduit')}
                                        /> </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapportrupture.categorie')}  <span className="text-danger">*</span></label>
                                        <SelectSearch
                                            options={listeCategorie}
                                            search
                                            ref={categorie}
                                            onChange={handleCategorie}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Magasins.ChoisirCategorie')}
                                        /> </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group text-center">
                                        <div>
                                            <label className="mr-2">
                                                <input type="radio" value="gamme" className=" mr-2" checked={etat2 === 'gamme'}
                                                    onChange={handleChangeEtat} />
                                                <span>{t('dashboard.Gamme')}</span>
                                            </label>
                                            <label className="mr-2">
                                                <input className="mr-2" type="radio" value="marque" checked={etat2 === 'marque'}
                                                    onChange={handleChangeEtat} />
                                                <span>{t('Produits.Marque')}</span>
                                            </label>
                                            <label className="mr-2">
                                                <input className="mr-2" type="radio" value="famille" checked={etat2 === 'famille'}
                                                    onChange={handleChangeEtat} />
                                                <span>{t('Produits.FAMILLE')}</span>
                                            </label>
                                            <label className="mr-2">
                                                <input className="mr-2" type="radio" value="sousfamille" checked={etat2 === 'sousfamille'}
                                                    onChange={handleChangeEtat} />
                                                <span>{t('Produits.SousFamille')}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    {etat2 === "gamme" ?
                                        <div className="form-group">
                                            <label className="form-label">{t('dashboard.Gamme')}</label>
                                            <select name="country" value={codeGamme} onChange={(e) => setcodeGamme(e.target.value)} className="form-control  custom-select">
                                                <option>---- {t('dashboard.Gamme')} -----</option>
                                                {listeGamme?.map((items, keys) => (
                                                    <option key={keys} value={items.value}>{items.name}</option>
                                                ))}
                                            </select>
                                        </div> : etat2 === "marque" ?
                                            <div className="form-group">
                                                <label className="form-label">{t('Produits.Marque')}</label>
                                                <select name="country" value={codeMarque} onChange={(e) => setcodeMarque(e.target.value)} className="form-control  custom-select">
                                                    <option>---- {t('Produits.Marque')} -----</option>
                                                    {listeMarque?.map((items, keys) => (
                                                        <option key={keys} value={items.value}>{items.name}</option>
                                                    ))}
                                                </select>
                                            </div> : etat2 === "famille" ?
                                                <div className="form-group">
                                                    <label className="form-label">{t('Produits.FAMILLE')}</label>
                                                    <select name="country" value={codeFamille} onChange={(e) => setcodeFamille(e.target.value)} className="form-control  custom-select">
                                                        <option>---- {t('Produits.FAMILLE')} -----</option>
                                                        {listeFamille?.map((items, keys) => (
                                                            <option key={keys} value={items.value}>{items.name}</option>
                                                        ))}
                                                    </select>
                                                </div> : etat2 === "sousfamille" ?
                                                    <div className="form-group">
                                                        <label className="form-label">{t('Produits.SousFamille')}</label>
                                                        <select name="country" value={codeSfamille} onChange={(e) => setcodeSfamille(e.target.value)} className="form-control  custom-select">
                                                            <option>---- {t('Produits.SousFamille')} -----</option>
                                                            {listeSFamille?.map((items, keys) => (
                                                                <option key={keys} value={items.code}>{items.libelle}</option>
                                                            ))}
                                                        </select>
                                                    </div> : null}
                                </div>
                            </>
                        )
                            : null}
                        {etat === 'importer' ? (
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label className="form-label">{t('Produits.Importer')}</label>
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" onChange={(e) => selectFile(e)} name="file" />
                                        <label className="custom-file-label"> ... {filename} </label>
                                        <p className=" text-azure " > ... {filename} </p>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <a className="text-primary" href={convention} download> <u>{t('Produits.telechargerModele')} </u></a>
                                </div>
                            </div>
                        )
                            : null}
                    </div>
                </div>
                <div className='card-footer'>
                    {etat === 'ajouter' ? (
                        <button className='btn btn-primary mt-2 float-right' onClick={() => saveData()}><i className='ti-save mr-1' /> {t('Magasins.Enregistrer')} </button>
                    ) : (<button className='btn btn-primary mt-2 float-right' onClick={() => uploadFile()}><i className='ti-save mr-1' /> {t('Magasins.Enregistrer')} </button>
                    )}
                </div>

            </div></div>
    )
}
